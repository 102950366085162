.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.backgorundColor{
  background-color:rgba(180, 171, 171, 0.063);
}
.custom-tour .reactour__step-content {
  color: red; /* Change to your desired text color */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-gradient {
  background-image: linear-gradient(to right, #2753f1, #feb47b);
  background-size: 200% auto;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: 0.5s;
}

.btn-gradient:hover {
  background-position: right center;
}

@keyframes gradientAnimation {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

/* styles.css */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-animated {
  animation: fadeInUp 0.5s ease-in-out;
}

.custom-card {
  transition: all 0.3s ease; /* Add animation transition */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add border shadow */
  background-color: #0d0909; /* Add background color */
}

.custom-card {
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  background-color: #ffffff; /* Normal background color */
}

.custom-card:hover {
  transform: scale(1.05);
  background-color: #f0f0f0; /* Background color on hover */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
}

.active-color > .a{
  color:red;
}
.featuresCard{
  transition: all 0.2s ease;
  cursor: pointer;
}
.featuresCard:hover{
  box-shadow: 5px 6px 6px 2px #e9ecef;
  transform: scale(1.1);
}


/* .navbar{
  width:65% !important;
  height: 7%;
  margin:0px auto;
  padding:0
} */
